<template>
  <div class="pointsRules-container point">
    <!-- 积分规则 -->
    <div style="position: relative;">
      <el-tabs v-model="tabType" @tab-click="tabClick">
        <el-tab-pane :label="$t('currentValidPointsRules')" name="effective">
          <div class="result-background">
            <div class="result-table ">
              <el-table
                :data="effectivePointListData"
                v-loading="isEffectiveListLoading"
                style="font-size:14px"
                row-key="id"
                :tree-props="{ children: 'childrenPointRules', hasChildren: 'hasChildren' }"
                stripe
                :indent="20"
                :default-expand-all="true"
              >
                <el-table-column prop="pointName" :label="$t('pointTypeLabel')" min-width="170" fixed></el-table-column>
                <el-table-column :label="$t('ruleIdLabel')" min-width="190" align="center">
                  <template slot-scope="scope"
                    ><p class="pointList-content">{{ scope.row.ruleId }}</p></template
                  >
                </el-table-column>
                <el-table-column :label="$t('activityTypeLabel')" min-width="130" align="center">
                  <template slot-scope="scope">{{ $t("activityTypeText" + scope.row.activityType) }}</template>
                </el-table-column>
                <el-table-column :label="$t('ruleNameLabel')" min-width="120">
                  <template slot-scope="scope"
                    ><p class="pointList-content" :title="scope.row.ruleName">{{ scope.row.ruleName }}</p></template
                  >
                </el-table-column>
                <el-table-column
                  prop="startTime"
                  :label="$t('startTimeLabel')"
                  min-width="170"
                  align="center"
                ></el-table-column>
                <el-table-column
                  prop="endTime"
                  :label="$t('endTimeLabel')"
                  min-width="170"
                  align="center"
                ></el-table-column>
                <el-table-column :label="$t('activityDayLabel')" min-width="100">
                  <template slot-scope="scope">
                    {{
                      scope.row.activityType == 2 ? $t("activityDayTypeText" + (scope.row.activityDayType || 0)) : ""
                    }}
                    <el-tag v-for="date in scope.row.activityDay" :key="date" size="mini" style="margin-left:3px">
                      {{
                        scope.row.activityDayType == 1
                          ? activityDayDate[date].label
                          : scope.row.activityDayType == 2
                          ? activityDayWeek[date].label
                          : ""
                      }}
                    </el-tag>
                  </template>
                </el-table-column>
                <el-table-column prop="ratio" :label="$t('ratioLabel')" align="center" min-width="80"></el-table-column>
                <el-table-column :label="$t('operation')" width="170" fixed="right" align="center">
                  <template slot-scope="scope">
                    <el-button type="text" @click="showAddPointsRule(scope)" v-if="scope.row.childrenPointRules">{{
                      $t("btnAddRuleText")
                    }}</el-button>
                    <el-button v-if="!scope.row.childrenPointRules" @click="showEditPointsRule(scope)" type="text">{{
                      $t("btnEditRuleText")
                    }}</el-button>
                    <el-button type="text" @click="showDetailPointsRule(scope)">{{
                      $t("btnDetailRuleText")
                    }}</el-button>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination
                background
                layout=" prev, pager, next, total"
                :total="effectiveTotal"
                :page-size="5"
                :current-page="effectiveCurrentPage"
                @current-change="effectiveCurrentChange"
                class="common-pagination"
              >
              </el-pagination>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane :label="$t('pointsRuleHistory')" name="historical">
          <!-- 按照店铺Code统计 -->
          <div class="search-query-container">
            <div class="search-query">
              <el-form label-position="top">
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item :label="$t('pointTypeLabel')">
                      <el-select v-model="searchTradeType" clearable style="width:100%">
                        <el-option
                          v-for="option in pointTypeOptions"
                          :key="option.tradeType"
                          :label="option.displayName"
                          :value="option.tradeType"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item :label="$t('ruleNameLabel')">
                      <el-input v-model="searchRuleName"></el-input>
                    </el-form-item>
                  </el-col>

                  <!-- <el-col :span="4" :push="4">
                <el-button class="confirm" size="small">{{$t("downloadPointsRulesList")}}</el-button>
              </el-col> -->
                </el-row>
              </el-form>

              <div class="search-btn">
                <el-button class="confirm" @click="clickSearchPointsRules">{{ $t("search") }}</el-button>
              </div>
            </div>
          </div>

          <div class="result-background" style="margin-top: 20px;">
            <div class="result-table">
              <el-table
                :data="historicalPointListData"
                v-loading="isHistoricalListLoading"
                ref="historicalRulesTable"
                style="font-size:14px"
                row-key="id"
                :tree-props="{ children: 'childrenPointRules', hasChildren: 'hasChildren' }"
                stripe
                :indent="20"
                :default-expand-all="true"
              >
                <el-table-column prop="pointName" :label="$t('pointTypeLabel')" min-width="170" fixed></el-table-column>
                <el-table-column :label="$t('stateLabel')" min-width="100" align="center">
                  <template slot-scope="scope">
                    <el-tooltip
                      class="item"
                      effect="dark"
                      :content="scope.row.activityStatus ? $t('activityStatusValid') : $t('activityStatusInvalid')"
                      placement="top-start"
                    >
                      <svg-icon :icon-class="scope.row.activityStatus ? 'success' : 'error'"></svg-icon>
                    </el-tooltip>
                  </template>
                </el-table-column>
                <el-table-column prop="ruleId" :label="$t('ruleIdLabel')" min-width="190" align="center">
                  <template slot-scope="scope"
                    ><p class="pointList-content">{{ scope.row.ruleId }}</p></template
                  >
                </el-table-column>
                <el-table-column :label="$t('activityTypeLabel')" min-width="130" align="center">
                  <template slot-scope="scope">{{ $t("activityTypeText" + scope.row.activityType) }}</template>
                </el-table-column>
                <el-table-column :label="$t('ruleNameLabel')" min-width="120">
                  <template slot-scope="scope"
                    ><p class="pointList-content" :title="scope.row.ruleName">{{ scope.row.ruleName }}</p></template
                  >
                </el-table-column>
                <el-table-column
                  prop="startTime"
                  :label="$t('startTimeLabel')"
                  min-width="170"
                  align="center"
                ></el-table-column>
                <el-table-column
                  prop="endTime"
                  :label="$t('endTimeLabel')"
                  min-width="170"
                  align="center"
                ></el-table-column>
                <el-table-column :label="$t('activityDayLabel')" min-width="100">
                  <template slot-scope="scope">
                    {{
                      scope.row.activityType == 2 ? $t("activityDayTypeText" + (scope.row.activityDayType || 0)) : ""
                    }}
                    <el-tag v-for="date in scope.row.activityDay" :key="date" size="mini" style="margin-left:3px">
                      {{
                        scope.row.activityDayType == 1
                          ? activityDayDate[date].label
                          : scope.row.activityDayType == 2
                          ? activityDayWeek[date].label
                          : ""
                      }}
                    </el-tag>
                  </template>
                </el-table-column>
                <el-table-column prop="ratio" :label="$t('ratioLabel')" min-width="80" align="center"></el-table-column>

                <el-table-column
                  prop="updateTime"
                  :label="$t('updateTimeLabel')"
                  min-width="180"
                  align="center"
                ></el-table-column>
                <el-table-column :label="$t('operation')" fixed="right" align="center">
                  <template slot-scope="scope">
                    <!-- <el-button type="text" @click="showDetailPointsRule(scope)">{{
                      $t("btnDetailRuleText")
                    }}</el-button> -->

                    <el-button size="mini" type="text" @click="showDetailPointsRule(scope)">
                      {{ $t("detail") }}
                      <i class="el-icon-arrow-right"></i>
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination
                background
                layout=" prev, pager, next, total"
                :total="historicalTotal"
                :page-size="5"
                :current-page="historicalCurrentPage"
                @current-change="historicalCurrentChange"
                class="common-pagination"
              >
              </el-pagination>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>

      <div class="pointList-subTitleBar-shopInfo">
        <span>{{ $t("storeName") + "：" + (shopInfo && shopInfo.storeName) }}</span>
        <span>{{ $t("storeCode") + "：" + (shopInfo && shopInfo.storeId) }}</span>
        <el-button class="confirm" size="small" @click="clickReturnShop">{{ $t("returnBtn") }}</el-button>
      </div>
    </div>

    <!-- 添加活动 -->
    <modal-dialog
      class="pointsDetails"
      v-if="isShowPointAddDialog"
      :show-dialog.sync="isShowPointAddDialog"
      :dialog-title="$t('addPointsRuleTitle')"
      :show-footer="true"
      @commitDialog="clickSubmitAddPointsRule(addPointsRuleForm)"
      dialogWidth="1000px"
    >
      <p class="pointsRule-tips">{{ $t("addPointsRuleTips") }}</p>
      <el-form
        ref="addPointsRuleForm"
        :model="addPointsRuleForm"
        :rules="addPointsRuleRules"
        label-width="190px"
        label-position="top"
      >
        <!-- 积分类型、活动类型 -->
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item :show-message="false">
              <p>{{ $t("pointTypeLabel") + "： " + addPointsRuleForm.pointName }}</p>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :show-message="false">
              <!-- <p>{{ $t("activityTypeLabel") + "： " + $t("activityTypeText" + addPointsRuleForm.activityType) }}</p> -->
              <p>{{ $t("activityTypeLabel") + "： " + $t("activityTypeText" + "2") }}</p>
              <!-- <p>240105 固定显示新增的活动类型为特殊：2</p>  -->
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 活动名称、活动有效期 -->
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item :label="$t('ruleNameLabel')" prop="ruleName">
              <el-input
                v-model.trim="addPointsRuleForm.ruleName"
                maxlength="50"
                class="pointsRule-input"
                show-word-limit
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('activityValidityPeriod')" prop="validityPeriod">
              <el-date-picker
                v-model="addPointsRuleForm.validityPeriod"
                type="daterange"
                @change="changeValidityPeriod"
                :picker-options="validityPeriodPickerOptions"
                value-format="yyyy-MM-dd"
                :default-value="activityMultipleDefault"
                :style="{ width: '100%' }"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 活动日-每天 -->

        <template v-if="false">
          <div prop="activityDayType">
            <el-form-item :label="$t(`activityDayLabel`)" class="is-required">
              <el-radio-group fill="#5a3ec3" v-model="smartData">
                <el-radio class="btnRadio" :label="3" border>{{ activityDayEveryday.label }}</el-radio>
                <el-radio class="btnRadio" :label="6" border>{{ $t("activityDayWeekText") }}</el-radio>
                <el-radio class="btnRadio" :label="9" border>{{ $t("activityDayDateText") }}</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item size="small" prop="activityDayType" inline-message="true" v-if="smartData == 3">
              <el-checkbox-group
                :disabled="!addPointsRuleForm.validityPeriod"
                v-model="addPointsRuleForm.activityDayEveryday"
                @change="changeActivityDayEveryday"
                fill="#5a3ec3"
              >
                <el-checkbox-button
                  :label="activityDayEveryday.value"
                  class="pointsRule-checkbox"
                  :checked="isEverydayChecked"
                  :disabled="isEverydayDisabled"
                  >{{ activityDayEveryday.label }}</el-checkbox-button
                >
              </el-checkbox-group>
            </el-form-item>
            <!-- 活动日-每周 -->
            <el-form-item size="small" v-if="smartData == 6" class="week">
              <el-checkbox-group
                :disabled="!addPointsRuleForm.validityPeriod"
                v-model="addPointsRuleForm.activityDayWeek"
                @change="changeActivityDayWeek"
                fill="#5a3ec3"
              >
                <el-checkbox-button
                  v-for="(week, key) in activityDayWeek"
                  :label="week.value"
                  :key="key"
                  :disabled="week.isDisabled"
                  >{{ week.label }}</el-checkbox-button
                >
              </el-checkbox-group>
            </el-form-item>
            <!-- 活动日-每月 -->
            <el-form-item size="small" v-if="smartData == 9" class="month">
              <el-checkbox-group
                :disabled="!addPointsRuleForm.validityPeriod"
                v-model="addPointsRuleForm.activityDayDate"
                @change="changeActivityDayMonth"
                fill="#5a3ec3"
              >
                <el-col :span="2" v-for="(date, key) in activityDayDate" :key="key" style="text-align:center">
                  <el-checkbox-button :label="date.value" class="pointsRule-checkbox" :disabled="date.isDisabled">{{
                    date.label
                  }}</el-checkbox-button>
                </el-col>
              </el-checkbox-group>
            </el-form-item>
          </div></template
        >

        <!-- 活动日-每天 -->
        <el-form-item
          :label="$t('activityDayLabel')"
          size="small"
          style="margin-bottom: 20px;"
          prop="activityDayType"
          inline-message="true"
        >
          <el-col :span="2">
            <p>{{ $t("activityDayEverydayText") }}</p>
          </el-col>
          <el-col :span="22">
            <el-checkbox-group
              :disabled="!addPointsRuleForm.validityPeriod"
              v-model="addPointsRuleForm.activityDayEveryday"
              @change="changeActivityDayEveryday"
              fill="#5a3ec3"
            >
              <el-checkbox-button
                :label="activityDayEveryday.value"
                class="pointsRule-checkbox"
                :checked="isEverydayChecked"
                :disabled="isEverydayDisabled"
                >{{ activityDayEveryday.label }}</el-checkbox-button
              >
            </el-checkbox-group>
          </el-col></el-form-item
        >
        <!-- 活动日-每周 -->
        <el-form-item size="small" style="margin-bottom: 10px;">
          <el-col :span="2">
            <p>{{ $t("activityDayWeekText") }}</p>
          </el-col>
          <el-col :span="22">
            <el-checkbox-group
              :disabled="!addPointsRuleForm.validityPeriod"
              v-model="addPointsRuleForm.activityDayWeek"
              @change="changeActivityDayWeek"
              fill="#5a3ec3"
            >
              <el-checkbox-button
                v-for="(week, key) in activityDayWeek"
                :label="week.value"
                :key="key"
                :disabled="week.isDisabled"
                >{{ week.label }}</el-checkbox-button
              >
            </el-checkbox-group>
          </el-col>
        </el-form-item>
        <!-- 活动日-每月 -->
        <el-form-item size="small" class="month">
          <el-col :span="2">
            <p>{{ $t("activityDayDateText") }}</p>
          </el-col>
          <el-col :span="22">
            <el-checkbox-group
              :disabled="!addPointsRuleForm.validityPeriod"
              v-model="addPointsRuleForm.activityDayDate"
              @change="changeActivityDayMonth"
              fill="#5a3ec3"
            >
              <el-col :span="2" v-for="(date, key) in activityDayDate" :key="key" style="text-align:center">
                <el-checkbox-button :label="date.value" class="pointsRule-checkbox" :disabled="date.isDisabled">{{
                  date.label
                }}</el-checkbox-button>
              </el-col>
            </el-checkbox-group>
          </el-col>
        </el-form-item>

        <!-- 倍数设定、进呈比例 -->
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item :label="$t('activityMultipleSetLabel')" size="small">
              <el-select
                v-model="addPointsRuleForm.activityMultiple"
                collapse-tags
                placeholder=""
                :style="{ width: '100%' }"
              >
                <el-option
                  v-for="item in activityMultipleList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item size="small" style="padding-top:42px;">
              <p>{{ $t("ratioLabel") + "： " + addPointsRuleForm.ratio }}</p>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 活动备注 -->
        <el-form-item :label="$t('activityRemarkLabel')" size="small" prop="activityRemark">
          <el-input
            v-model.trim="addPointsRuleForm.activityRemark"
            type="textarea"
            class="pointsRule-textarea"
            maxlength="100"
            rows="2"
            resize="none"
            show-word-limit
          ></el-input>
        </el-form-item>
      </el-form>
    </modal-dialog>

    <!-- 编辑活动 -->
    <modal-dialog
      class="pointsDetails"
      v-if="isShowPointEditDialog"
      :show-dialog.sync="isShowPointEditDialog"
      :dialog-title="$t('editPointsRuleTitle')"
      :show-footer="true"
      @commitDialog="clickSubmitEditPointsRule(editPointsRuleForm)"
      dialogWidth="1100px"
    >
      <el-form
        ref="editPointsRuleForm"
        :model="editPointsRuleForm"
        :rules="editPointsRuleRules"
        label-width="190px"
        label-position="top"
      >
        <!-- 积分类型、活动类型 -->
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item :show-message="false">
              <p>{{ $t("pointTypeLabel") + "：" + editPointsRuleForm.pointName }}</p>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :show-message="false">
              <p>{{ $t("activityTypeLabel") + "：" + $t("activityTypeText" + editPointsRuleForm.activityType) }}</p>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 活动名称、规则ID -->
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item :label="$t('ruleNameLabel')" prop="ruleName">
              <el-input
                v-model.trim="editPointsRuleForm.ruleName"
                class="pointsRule-input"
                maxlength="50"
                show-word-limit
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="false">
            <el-form-item :label="$t('originalRuleIdLabel')">
              {{ currentPointsRule.ruleId }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('activityValidityPeriod')" prop="validityPeriod">
              <el-date-picker
                v-model="editPointsRuleForm.validityPeriod"
                type="daterange"
                @change="changeValidityPeriod"
                :picker-options="validityPeriodPickerOptions"
                :default-value="activityMultipleDefault"
                value-format="yyyy-MM-dd"
                :style="{ width: '100%' }"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 活动有效期、基础活动有效期 -->
        <el-row :gutter="20" v-if="false">
          <el-col :span="12">
            <el-form-item :label="$t('ruleNameLabel')">
              <el-col v-if="$i18n.locale == 'en'" :span="4">{{ $t("fromText") }}</el-col>
              <el-col :span="8">{{ currentPointsRule.baseStartTime.slice(0, 10) }}</el-col>
              <el-col :span="3">{{ $i18n.locale == "en" ? $t("toText") : $t("fromText") }}</el-col>
              <el-col :span="8">{{ currentPointsRule.baseEndTime.slice(0, 10) }}</el-col>
              <el-col v-if="$i18n.locale != 'en'" :span="3">{{ $t("toText") }}</el-col>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 活动日-每日 -->
        <el-form-item :label="$t('activityDayLabel')" size="small" style="margin-bottom: 20px;" prop="activityDayType">
          <el-col :span="2"
            ><p>{{ $t("activityDayEverydayText") }}</p></el-col
          >
          <el-col :span="22">
            <el-checkbox-group
              :disabled="!editPointsRuleForm.validityPeriod"
              v-model="editPointsRuleForm.activityDayEveryday"
              @change="changeActivityDayEveryday"
              fill="#5a3ec3"
            >
              <el-checkbox-button
                :label="activityDayEveryday.value"
                class="pointsRule-checkbox"
                :checked="isEverydayChecked"
                :disabled="isEverydayDisabled"
                >{{ activityDayEveryday.label }}</el-checkbox-button
              >
            </el-checkbox-group>
          </el-col>
        </el-form-item>
        <!-- 活动日-每周 -->
        <el-form-item size="small" style="margin-bottom: 10px;">
          <el-col :span="2"
            ><p>{{ $t("activityDayWeekText") }}</p></el-col
          >
          <el-col :span="22">
            <el-checkbox-group
              :disabled="!editPointsRuleForm.validityPeriod"
              v-model="editPointsRuleForm.activityDayWeek"
              @change="changeActivityDayWeek"
              fill="#5a3ec3"
            >
              <el-checkbox-button
                v-for="(week, key) in activityDayWeek"
                :label="week.value"
                :key="key"
                :disabled="week.isDisabled"
                >{{ week.label }}</el-checkbox-button
              >
            </el-checkbox-group>
          </el-col>
        </el-form-item>
        <!-- 活动日-每月 -->
        <el-form-item size="small" class="month">
          <el-col :span="2"
            ><p>{{ $t("activityDayDateText") }}</p></el-col
          >
          <el-col :span="18">
            <el-checkbox-group
              :disabled="!editPointsRuleForm.validityPeriod"
              v-model="editPointsRuleForm.activityDayDate"
              @change="changeActivityDayMonth"
              fill="#5a3ec3"
            >
              <el-col :span="2" v-for="(date, key) in activityDayDate" :key="key" style="text-align:center">
                <el-checkbox-button :label="date.value" class="pointsRule-checkbox" :disabled="date.isDisabled">{{
                  date.label
                }}</el-checkbox-button>
              </el-col>
            </el-checkbox-group>
          </el-col>
        </el-form-item>
        <!-- 倍数设定、基础进呈比例 -->
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item :label="$t('activityMultipleSetLabel')" prop="activityMultiple" size="small">
              <el-select
                v-model="editPointsRuleForm.activityMultiple"
                collapse-tags
                placeholder=""
                :style="{ width: '100%' }"
              >
                <el-option
                  v-for="item in activityMultipleList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item style="padding-top:32px;">
              <p>{{ $t("baseRatioLabel") + "：" + currentPointsRule.baseRatio }}</p>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item style="padding-top:32px;">
              <p>{{ $t("ratioLabel") + "：" + editPointsRuleForm.ratio }}</p>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 进呈比例、活动状态 -->
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item :label="$t('stateLabel') + '：'">
              <el-select
                v-model="editPointsRuleForm.activityStatus"
                collapse-tags
                placeholder=""
                :style="{ width: '100%' }"
              >
                <el-option
                  v-for="item in activityStatusOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item :label="$t('activityRemarkLabel')" size="small" prop="activityRemark">
          <el-input
            v-model.trim="editPointsRuleForm.activityRemark"
            type="textarea"
            class="pointsRule-textarea"
            maxlength="100"
            rows="2"
            resize="none"
            show-word-limit
          ></el-input>
        </el-form-item>
      </el-form>
    </modal-dialog>

    <!-- 活动详情 -->
    <modal-dialog
      v-if="isShowPointDetailDialog"
      :show-dialog.sync="isShowPointDetailDialog"
      :dialog-title="$t('detailPointsRuleTitle')"
      :show-footer="false"
      dialogWidth="1000px"
      class="pointsRuleDetail-container"
    >
      <!-- 积分类型、活动类型 -->
      <el-row>
        <el-col :span="6" class="pointsRuleDetail-label" align="right">{{ $t("pointTypeLabel") + "：" }}</el-col>
        <el-col :span="6">{{ pointsRuleDetail.pointName }}</el-col>
        <el-col :span="4" class="pointsRuleDetail-label" align="right">{{ $t("activityTypeLabel") + "：" }}</el-col>
        <el-col :span="6">{{ $t("activityTypeText" + pointsRuleDetail.activityType) }}</el-col>
      </el-row>
      <!-- 规则ID、原规则ID、通用规则ID -->
      <el-row>
        <el-col :span="6" class="pointsRuleDetail-label" align="right">{{ $t("ruleIdLabel") + "：" }}</el-col>
        <el-col :span="6">{{ pointsRuleDetail.ruleId }}</el-col>
        <el-col v-if="pointsRuleDetail.originalRuleId" :span="4" class="pointsRuleDetail-label" align="right">{{
          $t("originalRuleIdLabel") + "："
        }}</el-col>
        <el-col v-if="pointsRuleDetail.originalRuleId" :span="6">{{ pointsRuleDetail.originalRuleId }}</el-col>
      </el-row>
      <!-- 活动发起端、活动名称 -->
      <el-row>
        <el-col :span="6" class="pointsRuleDetail-label" align="right">{{ $t("createSourceLabel") + "：" }}</el-col>
        <el-col :span="6">{{ pointsRuleDetail.createSource }}</el-col>
        <el-col :span="4" class="pointsRuleDetail-label" align="right">{{ $t("ruleNameLabel") + "：" }}</el-col>
        <el-col :span="6">{{ pointsRuleDetail.ruleName }}</el-col>
      </el-row>
      <!-- 活动有效期 -->
      <el-row>
        <el-col :span="6" class="pointsRuleDetail-label" align="right">{{ $t("activityValidityPeriod") }}</el-col>
        <el-col :span="16">{{
          ($i18n.locale == "en" ? $t("fromText") + "&nbsp;&nbsp;" : "") +
            pointsRuleDetail.startTime +
            "&nbsp;&nbsp;" +
            ($i18n.locale == "en" ? $t("toText") : $t("fromText")) +
            "&nbsp;&nbsp;" +
            pointsRuleDetail.endTime +
            "&nbsp;&nbsp;" +
            ($i18n.locale == "en" ? "" : $t("toText"))
        }}</el-col>
      </el-row>
      <!-- 基础活动有效期 -->
      <el-row v-if="pointsRuleDetail.activityType == 2">
        <el-col :span="6" class="pointsRuleDetail-label" align="right">{{ $t("baseActivityValidityPeriod") }}</el-col>
        <el-col :span="16">{{
          ($i18n.locale == "en" ? $t("fromText") + "&nbsp;&nbsp;" : "") +
            pointsRuleDetail.baseStartTime +
            "&nbsp;&nbsp;" +
            ($i18n.locale == "en" ? $t("toText") : $t("fromText")) +
            "&nbsp;&nbsp;" +
            pointsRuleDetail.baseEndTime +
            "&nbsp;&nbsp;" +
            ($i18n.locale == "en" ? "" : $t("toText"))
        }}</el-col>
      </el-row>
      <!-- 活动日 -->
      <el-row v-if="pointsRuleDetail.activityType == 2">
        <el-col :span="6" class="pointsRuleDetail-label" align="right">{{ $t("activityDayLabel") + "：" }}</el-col>
        <el-col :span="16">
          {{ $t("activityDayTypeText" + pointsRuleDetail.activityDayType) }}
          <el-tag v-for="date in pointsRuleDetail.activityDay" :key="date" size="mini" style="margin-left:3px">
            {{
              pointsRuleDetail.activityDayType == 1
                ? activityDayDate[date].label
                : pointsRuleDetail.activityDayType == 2
                ? activityDayWeek[date].label
                : ""
            }}
          </el-tag>
        </el-col>
      </el-row>
      <!-- 基础进呈比例、活动倍数 -->
      <el-row>
        <el-col v-if="pointsRuleDetail.baseRatio" :span="6" class="pointsRuleDetail-label" align="right">{{
          $t("baseRatioLabel") + "："
        }}</el-col>
        <el-col v-if="pointsRuleDetail.baseRatio" :span="6">{{ pointsRuleDetail.baseRatio }}</el-col>
        <el-col v-if="pointsRuleDetail.activityMultiplier > 0" :span="4" class="pointsRuleDetail-label" align="right">{{
          $t("activityMultipleLabel")
        }}</el-col>
        <el-col v-if="pointsRuleDetail.activityMultiplier > 0" :span="6">{{
          pointsRuleDetail.activityMultiplier
        }}</el-col>
      </el-row>
      <!-- 进呈比例、状态 -->
      <el-row>
        <el-col :span="6" class="pointsRuleDetail-label" align="right">{{ $t("ratioLabel") + "：" }}</el-col>
        <el-col :span="6">{{ pointsRuleDetail.ratio }}</el-col>
        <el-col :span="4" class="pointsRuleDetail-label" align="right">{{ $t("stateLabel") + "：" }}</el-col>
        <el-col :span="6">{{
          pointsRuleDetail.activityStatus ? $t("activityStatusValid") : $t("activityStatusInvalid")
        }}</el-col>
      </el-row>
      <!-- 新增时间、更新时间 -->
      <el-row>
        <el-col :span="6" class="pointsRuleDetail-label" align="right">{{
          $t("activicyCreateTimeLabel") + "："
        }}</el-col>
        <el-col :span="6">{{ pointsRuleDetail.createTime }}</el-col>
        <el-col v-if="pointsRuleDetail.updateTime" :span="4" class="pointsRuleDetail-label" align="right">{{
          $t("updateTimeLabel") + "："
        }}</el-col>
        <el-col v-if="pointsRuleDetail.updateTime" :span="6">{{ pointsRuleDetail.updateTime }}</el-col>
      </el-row>
      <!-- 活动备注 -->
      <el-row>
        <el-col :span="6" class="pointsRuleDetail-label" align="right">{{ $t("activityRemarkLabel") }}</el-col>
        <el-col :span="16">{{ pointsRuleDetail.remarks }}</el-col>
      </el-row>
      <el-row>
        <el-col align="right">
          <el-button @click="clickClosePointsRuleDetail">{{ $t("cancel") }}</el-button>
        </el-col>
      </el-row>
    </modal-dialog>

    <side-layer :show-side-layer.sync="detailDrawer">
      <div v-loading="detailLoading">
        <div class="setting-nav-title">{{ $t("detailPointsRuleTitle") }}</div>

        <div class="dataTable">
          <div class="dataItem">
            <td class="name">{{ $t("pointTypeLabel") }}</td>
            <td class="value">
              {{ pointsRuleDetail.pointName }}
            </td>
          </div>
          <div class="dataItem">
            <td class="name">{{ $t("activityTypeLabel") }}</td>
            <td class="value">
              {{ $t("activityTypeText" + pointsRuleDetail.activityType) }}
            </td>
          </div>
          <div class="dataItem">
            <td class="name">{{ $t("ruleIdLabel") }}</td>
            <td class="value">
              {{ pointsRuleDetail.ruleId }}
            </td>
          </div>
          <div class="dataItem" v-if="pointsRuleDetail.originalRuleId">
            <td class="name">{{ $t("originalRuleIdLabel") }}</td>
            <td class="value">
              {{ pointsRuleDetail.originalRuleId }}
            </td>
          </div>
          <div class="dataItem">
            <td class="name">{{ $t("createSourceLabel") }}</td>
            <td class="value">
              {{ pointsRuleDetail.createSource }}
            </td>
          </div>
          <div class="dataItem">
            <td class="name">{{ $t("ruleNameLabel") }}</td>
            <td class="value">
              {{ pointsRuleDetail.ruleName }}
            </td>
          </div>
          <div class="dataItem">
            <td class="name">{{ $t("activityValidityPeriod") }}</td>
            <td class="value">
              <p>
                {{
                  ($i18n.locale == "en" ? $t("fromText") + "&nbsp;&nbsp;" : "") +
                    pointsRuleDetail.startTime +
                    "&nbsp;&nbsp;" +
                    ($i18n.locale == "en" ? $t("toText") : $t("fromText")) +
                    "&nbsp;&nbsp;"
                }}
              </p>
              <p>{{ pointsRuleDetail.endTime + "&nbsp;&nbsp;" + ($i18n.locale == "en" ? "" : $t("toText")) }}</p>
            </td>
          </div>
          <div class="dataItem" v-if="pointsRuleDetail.activityType == 2">
            <td class="name">{{ $t("baseActivityValidityPeriod") }}</td>
            <td class="value">
              <p>
                {{
                  ($i18n.locale == "en" ? $t("fromText") + "&nbsp;&nbsp;" : "") +
                    pointsRuleDetail.baseStartTime +
                    "&nbsp;&nbsp;" +
                    ($i18n.locale == "en" ? $t("toText") : $t("fromText")) +
                    "&nbsp;&nbsp;"
                }}
              </p>
              <p>{{ pointsRuleDetail.baseEndTime + "&nbsp;&nbsp;" + ($i18n.locale == "en" ? "" : $t("toText")) }}</p>
            </td>
          </div>
          <div class="dataItem" v-if="pointsRuleDetail.activityType == 2">
            <td class="name">{{ $t("activityDayLabel") }}</td>
            <td class="value">
              {{ $t("activityDayTypeText" + pointsRuleDetail.activityDayType) }}
              <el-tag v-for="date in pointsRuleDetail.activityDay" :key="date" size="mini" style="margin-left:3px">
                {{
                  pointsRuleDetail.activityDayType == 1
                    ? activityDayDate[date].label
                    : pointsRuleDetail.activityDayType == 2
                    ? activityDayWeek[date].label
                    : ""
                }}
              </el-tag>
            </td>
          </div>
          <div class="dataItem" v-if="pointsRuleDetail.baseRatio">
            <td class="name">{{ $t("baseRatioLabel") }}</td>
            <td class="value">
              {{ pointsRuleDetail.baseRatio }}
            </td>
          </div>
          <div class="dataItem" v-if="pointsRuleDetail.activityMultiplier > 0">
            <td class="name">{{ $t("activityMultipleLabel") }}</td>
            <td class="value">
              {{ pointsRuleDetail.activityMultiplier }}
            </td>
          </div>
          <div class="dataItem">
            <td class="name">{{ $t("ratioLabel") }}</td>
            <td class="value">
              {{ pointsRuleDetail.ratio }}
            </td>
          </div>
          <div class="dataItem">
            <td class="name">{{ $t("stateLabel") }}</td>
            <td class="value">
              {{ pointsRuleDetail.activityStatus ? $t("activityStatusValid") : $t("activityStatusInvalid") }}
            </td>
          </div>
          <div class="dataItem">
            <td class="name">{{ $t("activicyCreateTimeLabel") }}</td>
            <td class="value">
              {{ pointsRuleDetail.createTime }}
            </td>
          </div>
          <div class="dataItem" v-if="pointsRuleDetail.updateTime">
            <td class="name">{{ $t("updateTimeLabel") }}</td>
            <td class="value">
              {{ pointsRuleDetail.updateTime }}
            </td>
          </div>
          <div class="dataItem">
            <td class="name">{{ $t("activityRemarkLabel") }}</td>
            <td class="value">
              {{ pointsRuleDetail.remarks }}
            </td>
          </div>
        </div>
      </div>
    </side-layer>
  </div>
</template>

<script>
import {
  getEffectivePointList,
  getHistoricalPointList,
  getPointTypeOptions,
  getPointsRuleDetail,
  submitCreatePointsRule,
  submitEditPointsRule
} from "api/shop";
export default {
  data() {
    return {
      detailDrawer: false,
      detailLoading: false,
      smartData: "",
      isEffectiveListLoading: false,
      isHistoricalListLoading: false,
      isShowPointAddDialog: false,
      isShowPointEditDialog: false,
      isShowPointDetailDialog: false,
      isCanSubmitAddRule: true,
      isCanSubmitEditRule: true,
      effectiveTotal: 0,
      historicalTotal: 0,
      effectiveCurrentPage: 1,
      historicalCurrentPage: 1,
      currentPageSize: 5,
      shopInfo: this.$route.params.shopInfo,
      currentPointsRule: {},
      existingActivityDay: {},
      isEverydayDisabled: true,
      isEverydayChecked: false,
      currentDialogForm: "",
      pointsRuleDetail: {},
      pointTypeOptions: [],
      searchTradeType: "",
      searchRuleName: "",
      activityMultipleDefault: "",
      addPointsRuleForm: {
        pointName: "",
        pointType: "",
        activityType: "",
        ruleName: "",
        validityPeriod: null,
        activityDayDate: [],
        activityDayWeek: [],
        activityDayEveryday: [],
        activityMultiple: "2",
        activityRemark: "",
        activityDayType: "",
        ratio: ""
      },
      addPointsRuleRules: {
        ruleName: [
          { required: true, message: this.$t("ruleNameRequiredValid"), trigger: ["blur", "change"] },
          { min: 1, max: 50, message: this.$t("ruleNameLengthValid"), trigger: ["blur", "change"] }
        ],
        validityPeriod: [
          { required: true, message: this.$t("activityValidityPeriodRequiredValid"), trigger: ["blur", "change"] }
        ],
        activityRemark: [
          { min: 1, max: 100, message: this.$t("activityRemarkLengthValid"), trigger: ["blur", "change"] }
        ],
        activityDayType: [{ required: true, message: this.$t("activityDayRequiredValid"), trigger: ["blur", "change"] }]
      },
      editPointsRuleForm: {
        pointName: "",
        pointType: "",
        activityType: "",
        ruleId: "",
        ruleName: "",
        validityPeriod: [],
        activityDayDate: [],
        activityDayWeek: [],
        activityDayEveryday: [],
        activityMultiple: "2",
        activityStatus: true,
        activityRemark: "",
        activityDayType: "",
        ratio: ""
      },
      editPointsRuleRules: {
        ruleName: [
          { required: true, message: this.$t("ruleNameRequiredValid"), trigger: ["blur", "change"] },
          { min: 1, max: 50, message: this.$t("ruleNameLengthValid"), trigger: ["blur", "change"] }
        ],
        validityPeriod: [
          { required: true, message: this.$t("activityValidityPeriodRequiredValid"), trigger: ["blur", "change"] }
        ],
        activityRemark: [
          { min: 1, max: 100, message: this.$t("activityRemarkLengthValid"), trigger: ["blur", "change"] }
        ],
        activityDayType: [{ required: true, message: this.$t("activityDayRequiredValid"), trigger: ["blur", "change"] }]
      },
      effectivePointListData: [],
      historicalPointListData: [],
      activityStatusOptions: [
        { value: true, label: this.$t("activityStatusValid") },
        { value: false, label: this.$t("activityStatusInvalid") }
      ],
      activityDayDate: {
        1: { value: "1", label: "1", isDisabled: true },
        2: { value: "2", label: "2", isDisabled: true },
        3: { value: "3", label: "3", isDisabled: true },
        4: { value: "4", label: "4", isDisabled: true },
        5: { value: "5", label: "5", isDisabled: true },
        6: { value: "6", label: "6", isDisabled: true },
        7: { value: "7", label: "7", isDisabled: true },
        8: { value: "8", label: "8", isDisabled: true },
        9: { value: "9", label: "9", isDisabled: true },
        10: { value: "10", label: "10", isDisabled: true },
        11: { value: "11", label: "11", isDisabled: true },
        12: { value: "12", label: "12", isDisabled: true },
        13: { value: "13", label: "13", isDisabled: true },
        14: { value: "14", label: "14", isDisabled: true },
        15: { value: "15", label: "15", isDisabled: true },
        16: { value: "16", label: "16", isDisabled: true },
        17: { value: "17", label: "17", isDisabled: true },
        18: { value: "18", label: "18", isDisabled: true },
        19: { value: "19", label: "19", isDisabled: true },
        20: { value: "20", label: "20", isDisabled: true },
        21: { value: "21", label: "21", isDisabled: true },
        22: { value: "22", label: "22", isDisabled: true },
        23: { value: "23", label: "23", isDisabled: true },
        24: { value: "24", label: "24", isDisabled: true },
        25: { value: "25", label: "25", isDisabled: true },
        26: { value: "26", label: "26", isDisabled: true },
        27: { value: "27", label: "27", isDisabled: true },
        28: { value: "28", label: "28", isDisabled: true },
        29: { value: "29", label: "29", isDisabled: true },
        30: { value: "30", label: "30", isDisabled: true },
        31: { value: "31", label: "31", isDisabled: true },
        32: { value: "32", label: this.$t("activityDayDateLastText"), isDisabled: true }
      },
      activityDayWeek: {
        1: { value: "1", label: this.$t("activityDayWeekText1"), isDisabled: true },
        2: { value: "2", label: this.$t("activityDayWeekText2"), isDisabled: true },
        3: { value: "3", label: this.$t("activityDayWeekText3"), isDisabled: true },
        4: { value: "4", label: this.$t("activityDayWeekText4"), isDisabled: true },
        5: { value: "5", label: this.$t("activityDayWeekText5"), isDisabled: true },
        6: { value: "6", label: this.$t("activityDayWeekText6"), isDisabled: true },
        7: { value: "7", label: this.$t("activityDayWeekText7"), isDisabled: true }
      },
      activityDayEveryday: { value: "1", label: this.$t("activityDayEverydayText"), isDisabled: true },
      activityMultipleList: [
        { value: "2", label: "2" },
        { value: "3", label: "3" },
        { value: "4", label: "4" },
        { value: "5", label: "5" },
        { value: "6", label: "6" },
        { value: "7", label: "7" },
        { value: "8", label: "8" },
        { value: "9", label: "9" },
        { value: "10", label: "10" },
        { value: "11", label: "11" },
        { value: "12", label: "12" },
        { value: "13", label: "13" },
        { value: "14", label: "14" },
        { value: "15", label: "15" },
        { value: "16", label: "16" },
        { value: "17", label: "17" },
        { value: "18", label: "18" },
        { value: "19", label: "19" },
        { value: "20", label: "20" }
      ],
      monthLastDayList: {
        "0131": true,
        "0228": true,
        "0331": true,
        "0430": true,
        "0531": true,
        "0630": true,
        "0731": true,
        "0831": true,
        "0930": true,
        "1031": true,
        "1130": true,
        "1231": true
      },
      monthLastDayLeapYearList: {
        "0131": true,
        "0229": true,
        "0331": true,
        "0430": true,
        "0531": true,
        "0630": true,
        "0731": true,
        "0831": true,
        "0930": true,
        "1031": true,
        "1130": true,
        "1231": true
      },

      validityPeriodPickerOptions: this.disabledData(),
      tabType: "effective"
    };
  },
  methods: {
    tabClick(e) {
      this.getList(e.name, 1);
    },

    getList(listName, pageIndex, pageSize = this.currentPageSize) {
      let params = {
        StoreId: this.shopInfo.storeId,
        PageSize: pageSize
      };
      if (listName == "effective") {
        this.isEffectiveListLoading = true;
        params.PageIndex = pageIndex ? pageIndex : this.effectiveCurrentPage;
        getEffectivePointList(params).then(response => {
          this.isEffectiveListLoading = false;
          if (response.statusCode === 200 && response.resultStatus === "Success") {
            this.effectivePointListData = response.result;
            this.effectiveCurrentPage = pageIndex || 1;
            this.effectiveTotal = response.totalCount;
            for (var i = 0; i < this.effectivePointListData.length; i++) {
              for (var j = 0; j < this.effectivePointListData[i].childrenPointRules.length; j++) {
                this.effectivePointListData[i].childrenPointRules[j].baseStartTime = this.effectivePointListData[
                  i
                ].startTime;
                this.effectivePointListData[i].childrenPointRules[j].baseEndTime = this.effectivePointListData[
                  i
                ].endTime;
              }
            }
          }
        });
      } else if (listName == "historical") {
        this.isHistoricalListLoading = true;
        params.PageIndex = pageIndex ? pageIndex : this.historicalCurrentPage;
        params.TradeType = this.searchTradeType || null;
        params.RuleName = this.searchRuleName || null;
        getHistoricalPointList(params).then(response => {
          this.isHistoricalListLoading = false;
          if (response.statusCode === 200 && response.resultStatus === "Success") {
            this.historicalPointListData.splice(0, this.historicalPointListData.length);
            this.historicalPointListData = response.result;
            this.historicalTotal = response.totalCount;
            this.historicalCurrentPage = pageIndex || 1;
          }
        });
      }
    },
    getPointTypeOptions() {
      getPointTypeOptions().then(response => {
        if (response.statusCode == 200 && response.resultStatus === "Success") {
          this.pointTypeOptions = response.result;
        }
      });
    },
    effectiveCurrentChange(page) {
      this.effectiveCurrentPage = page;
      this.getList("effective", page);
    },
    historicalCurrentChange(page) {
      this.historicalCurrentPage = page;
      this.getList("historical", page);
    },
    showAddPointsRule(scope) {
      this.currentPointsRule = scope.row;
      this.currentDialogForm = "addPointsRuleForm";
      this.filterActivityDate(scope.row);
      this.activityMultipleDefault = new Date(Math.max(new Date(scope.row.startTime).getTime(), new Date().getTime()));
      this.addPointsRuleForm = {
        pointName: scope.row.pointName,
        pointType: scope.row.pointType,
        activityType: scope.row.activityType,
        ruleName: "",
        validityPeriod: null,
        activityDayDate: [],
        activityDayWeek: [],
        activityDayEveryday: [],
        activityMultiple: "2",
        activityRemark: "",
        activityDayType: ""
      };
      this.isShowPointAddDialog = true;
    },
    showEditPointsRule(scope) {
      getPointsRuleDetail({ id: scope.row.id }).then(response => {
        if (response.statusCode === 200 && response.resultStatus === "Success") {
          this.currentPointsRule = response.result;
          this.currentDialogForm = "editPointsRuleForm";
          this.filterActivityDate(this.currentPointsRule);
          this.activityMultipleDefault = new Date(
            Math.max(new Date(scope.row.startTime).getTime(), new Date().getTime())
          );
          this.editPointsRuleForm = {
            pointName: this.currentPointsRule.pointName,
            pointType: this.currentPointsRule.pointType,
            activityType: this.currentPointsRule.activityType,
            ruleName: this.currentPointsRule.ruleName,
            validityPeriod: [
              this.currentPointsRule.startTime.slice(0, 10),
              this.currentPointsRule.endTime.slice(0, 10)
            ],
            activityDayDate: [],
            activityDayWeek: [],
            activityDayEveryday: [],
            activityMultiple: this.currentPointsRule.activityMultiplier,
            activityStatus: this.currentPointsRule.activityStatus,
            activityRemark: this.currentPointsRule.remarks,
            activityDayType: this.currentPointsRule.activityDayType
          };
          this.changeValidityPeriod(this.editPointsRuleForm.validityPeriod);
          this.editPointsRuleForm.activityDayDate = [];
          this.editPointsRuleForm.activityDayWeek = [];
          this.editPointsRuleForm.activityDayEveryday = [];
          switch (this.currentPointsRule.activityDayType) {
            case 0:
              this.editPointsRuleForm.activityDayEveryday.push("1");
              break;
            case 1:
              for (let i = 0; i < this.currentPointsRule.activityDay.length; i++) {
                if (!this.activityDayDate[this.currentPointsRule.activityDay[i]].isDisabled) {
                  this.editPointsRuleForm.activityDayDate.push(this.currentPointsRule.activityDay[i]);
                }
              }
              break;
            case 2:
              for (let i = 0; i < this.currentPointsRule.activityDay.length; i++) {
                if (!this.activityDayWeek[this.currentPointsRule.activityDay[i]].isDisabled) {
                  this.editPointsRuleForm.activityDayWeek.push(this.currentPointsRule.activityDay[i]);
                }
              }
              break;
            default:
              break;
          }
          this.isShowPointEditDialog = true;
        }
      });
    },
    showDetailPointsRule(scope) {
      this.detailDrawer = true;
      this.detailLoading = true;
      getPointsRuleDetail({ id: scope.row.id }).then(response => {
        this.detailLoading = false;
        if (response.statusCode === 200 && response.resultStatus === "Success") {
          this.pointsRuleDetail = response.result;
          // this.isShowPointDetailDialog = true;
        }
      });
    },
    clickSubmitAddPointsRule(formData) {
      this.$refs["addPointsRuleForm"].validate(valid => {
        if (valid && this.isCanSubmitAddRule) {
          let params = {
            storeId: this.shopInfo.storeId,
            pointType: this.currentPointsRule.pointType,
            baseRuleKey: this.currentPointsRule.id,
            baseRuleId: this.currentPointsRule.ruleId,
            ruleName: formData.ruleName,
            startTime: formData.validityPeriod[0],
            endTime: formData.validityPeriod[1],
            activityDayType: null,
            activityDay: [],
            ratioMoney: formData.ratio.split(":")[0],
            ratioPoint: formData.ratio.split(":")[1],
            remarks: formData.activityRemark
          };
          if (formData.activityDayEveryday.length > 0) {
            params.activityDayType = 0;
          } else if (formData.activityDayDate.length > 0) {
            params.activityDayType = 1;
            params.activityDay = formData.activityDayDate;
          } else if (formData.activityDayWeek.length > 0) {
            params.activityDayType = 2;
            params.activityDay = formData.activityDayWeek;
          } else {
            this.$refs["addPointsRuleForm"].validateField().then();
            return false;
          }
          this.isCanSubmitAddRule = false;
          submitCreatePointsRule(params).then(response => {
            if (response.statusCode === 200 && response.resultStatus === "Success") {
              this.isShowPointAddDialog = false;
              this.getList("effective");
              this.getList("historical");
            }
            this.isCanSubmitAddRule = true;
          });
        } else {
          return false;
        }
      });
    },
    clickSubmitEditPointsRule(formData) {
      this.$refs["editPointsRuleForm"].validate(valid => {
        if (valid && this.isCanSubmitEditRule) {
          let params = {
            id: this.currentPointsRule.id,
            ruleName: formData.ruleName,
            startTime: formData.validityPeriod[0],
            endTime: formData.validityPeriod[1],
            activityDayType: null,
            activityDay: [],
            ratioMoney: formData.ratio.split(":")[0],
            ratioPoint: formData.ratio.split(":")[1],
            enable: formData.activityStatus,
            remarks: formData.activityRemark
          };
          if (formData.activityDayEveryday.length > 0) {
            params.activityDayType = 0;
          } else if (formData.activityDayDate.length > 0) {
            params.activityDayType = 1;
            params.activityDay = formData.activityDayDate;
          } else if (formData.activityDayWeek.length > 0) {
            params.activityDayType = 2;
            params.activityDay = formData.activityDayWeek;
          } else {
            return false;
          }
          this.isCanSubmitEditRule = false;
          submitEditPointsRule(params).then(response => {
            if (response.statusCode === 200 && response.resultStatus === "Success") {
              this.isShowPointEditDialog = false;
              this.getList("effective");
              this.getList("historical");
            }
            this.isCanSubmitEditRule = true;
          });
        } else {
          return false;
        }
      });
    },
    changeActivityDayEveryday() {
      const everydayLen = this[this.currentDialogForm].activityDayEveryday.length;
      if (everydayLen) {
        this[this.currentDialogForm].activityDayWeek = [];
        this[this.currentDialogForm].activityDayDate = [];
      }
    },
    changeActivityDayWeek() {
      const everydayLen = this[this.currentDialogForm].activityDayEveryday.length;
      const weekLen = this[this.currentDialogForm].activityDayWeek.length;
      this[this.currentDialogForm].activityDayDate = [];
      if (weekLen) {
        this[this.currentDialogForm].activityDayEveryday.splice(0, everydayLen);
      } else if (!this.isEverydayDisabled) {
        this[this.currentDialogForm].activityDayEveryday.splice(0, 0, "1");
      }
    },
    changeActivityDayMonth() {
      const everydayLen = this[this.currentDialogForm].activityDayEveryday.length;
      const monthLen = this[this.currentDialogForm].activityDayDate.length;
      this[this.currentDialogForm].activityDayWeek = [];
      if (monthLen) {
        this[this.currentDialogForm].activityDayEveryday.splice(0, everydayLen);
      } else if (!this.isEverydayDisabled) {
        this[this.currentDialogForm].activityDayEveryday.splice(0, 0, "1");
      }
    },
    changeValidityPeriod(dataRange) {
      let existingDay = this.existingActivityDay;

      this.isEverydayDisabled = false;
      this[this.currentDialogForm].activityDayDate = [];
      this[this.currentDialogForm].activityDayWeek = [];
      this[this.currentDialogForm].activityDayEveryday = [];

      for (let date in this.activityDayDate) {
        this.activityDayDate[date].isDisabled = true;
      }

      for (let week in this.activityDayWeek) {
        this.activityDayWeek[week].isDisabled = true;
      }

      if (dataRange && dataRange.length == 2) {
        let startTime = new Date(dataRange[0]).getTime();
        let EndTime = new Date(dataRange[1]).getTime();
        let newRuleDay = {};

        for (let i = startTime; i <= EndTime; i += 3600 * 1000 * 24) {
          let d = new Date(i).getDate();
          let m = new Date(i).getMonth() + 1;
          let w = new Date(i).getDay() || 7;
          let y = new Date(i).getFullYear();
          let isLeapY = this.isLeapYear(y);
          let md = "" + (m < 10 ? "0" + m : m) + (d < 10 ? "0" + d : d);
          let ymd = "" + y + md;
          let isLastDate = (isLeapY ? this.monthLastDayLeapYearList[md] : this.monthLastDayList[md]) || false;
          newRuleDay[ymd] = [d, w, isLastDate ? 32 : null];
          this.activityDayDate[d].isDisabled = false;
          isLastDate && (this.activityDayDate[32].isDisabled = false);
          this.activityDayWeek[w].isDisabled = false;
        }

        for (var newRule in newRuleDay) {
          for (var existingRule in existingDay) {
            if (newRule == existingRule) {
              this.activityDayDate[newRuleDay[newRule][0]].isDisabled = true;
              this.activityDayWeek[newRuleDay[newRule][1]].isDisabled = true;
              newRuleDay[newRule][2] && (this.activityDayDate[newRuleDay[newRule][2]].isDisabled = true);
              this.isEverydayDisabled = true;
              break;
            }
          }
        }

        if (
          !this[this.currentDialogForm].activityDayDate.length &&
          !this[this.currentDialogForm].activityDayWeek.length &&
          !this.isEverydayDisabled
        ) {
          this[this.currentDialogForm].activityDayEveryday.push("1");
        }
      }
    },
    clickReturnShop() {
      this.$router.push({
        name: "Shop",
        params: {
          pageIndex: this.$route.params.pageIndex
        }
      });
    },
    disabledData() {
      let that = this;
      return {
        disabledDate(time) {
          const startTime = that.currentPointsRule.baseStartTime || that.currentPointsRule.startTime;
          const endTime = that.currentPointsRule.baseEndTime || that.currentPointsRule.endTime;
          const minDate =
            time.getTime() < Date.now() - 3600 * 1000 * 24 ||
            time.getTime() < new Date(startTime).getTime() - 3600 * 1000 * 24;
          const maxDate = time.getTime() > new Date(endTime).getTime();

          return minDate || maxDate;
        }
      };
    },
    filterActivityDate(pointsRuleInfo) {
      const commonRuleStartTime = new Date(pointsRuleInfo.startTime.substr(0, 10)).getTime();
      const commonRuleEndTime = new Date(pointsRuleInfo.endTime.substr(0, 10)).getTime();
      const pointRulesList =
        this.currentDialogForm == "addPointsRuleForm"
          ? pointsRuleInfo.childrenPointRules
          : pointsRuleInfo.siblingPointRules;
      let commonRuleTimeList = [];
      let subRuleTimeList = {};

      for (let i = commonRuleStartTime; i <= commonRuleEndTime; i += 3600 * 1000 * 24) {
        commonRuleTimeList.push(new Date(i).getDate());
      }
      for (let i = 0; i < pointRulesList.length; i++) {
        let startTime = new Date(pointRulesList[i].startTime.substr(0, 10)).getTime();
        let EndTime = new Date(pointRulesList[i].endTime.substr(0, 10)).getTime();
        for (let j = startTime; j <= EndTime; j += 3600 * 1000 * 24) {
          let d = new Date(j).getDate();
          let m = new Date(j).getMonth() + 1;
          let w = new Date(j).getDay() || 7;
          let y = new Date(j).getFullYear();
          let isLeapY = this.isLeapYear(y);
          let md = "" + (m < 10 ? "0" + m : m) + (d < 10 ? "0" + d : d);
          let ymd = "" + y + md;
          let isHave = false;
          if (pointRulesList[i].activityDay.length > 0) {
            for (var p = 0; p < pointRulesList[i].activityDay.length; p++) {
              if (pointRulesList[i].activityDayType == 1) {
                if (d == pointRulesList[i].activityDay[p]) {
                  isHave = true;
                  break;
                } else if (pointRulesList[i].activityDay[p] == 32) {
                  isHave = (isLeapY ? this.monthLastDayLeapYearList[md] : this.monthLastDayList[md]) || false;
                }
              } else if (pointRulesList[i].activityDayType == 2) {
                if (w == pointRulesList[i].activityDay[p]) {
                  isHave = true;
                  break;
                }
              }
            }
            if (isHave) {
              subRuleTimeList[ymd] = [d, w];
            }
          } else {
            subRuleTimeList[ymd] = [d, w];
          }
        }
      }
      this.existingActivityDay = subRuleTimeList;
    },
    isLeapYear(year) {
      if (year % 4 == 0 && year % 100 != 0) {
        return true;
      } else if (year % 400 == 0) {
        return true;
      } else {
        return false;
      }
    },
    clickSearchPointsRules() {
      this.getList("historical", 1);
    },
    clickClosePointsRuleDetail() {
      this.isShowPointDetailDialog = false;
    }
  },
  mounted() {
    if (this.$route.params.shopInfo) {
      this.getPointTypeOptions();
      this.getList("effective");
      this.getList("historical");
    }
  },
  computed: {
    addActivityDayType: function() {
      let type = "";
      if (this.addPointsRuleForm.activityDayEveryday.length > 0) {
        type = 0;
      } else if (this.addPointsRuleForm.activityDayDate.length > 0) {
        type = 1;
      } else if (this.addPointsRuleForm.activityDayWeek.length > 0) {
        type = 2;
      }
      return type;
    },
    editActivityDayType: function() {
      let type = null;
      if (this.editPointsRuleForm.activityDayEveryday.length > 0) {
        type = 0;
      } else if (this.editPointsRuleForm.activityDayDate.length > 0) {
        type = 1;
      } else if (this.editPointsRuleForm.activityDayWeek.length > 0) {
        type = 2;
      }
      return type;
    }
  },
  watch: {
    addActivityDayType: {
      handler: function(val) {
        this.addPointsRuleForm.activityDayType = val;
        if (this.$refs["addPointsRuleForm"]) {
          this.$refs["addPointsRuleForm"].validateField("activityDayType");
        }
      },
      deep: true
    },
    editActivityDayType: {
      handler: function(val) {
        this.editPointsRuleForm.activityDayType = val;
        if (this.$refs["editPointsRuleForm"]) {
          this.$refs["editPointsRuleForm"].validateField("activityDayType");
        }
      },
      deep: true
    },
    "addPointsRuleForm.activityMultiple": {
      handler: function(val) {
        this.addPointsRuleForm.ratio =
          this.currentPointsRule.ratio.split(":")[0] +
          " : " +
          parseInt(this.currentPointsRule.ratio.split(":")[1]) * this.addPointsRuleForm.activityMultiple;
      },
      deep: true
    },
    "editPointsRuleForm.activityMultiple": {
      handler: function(val) {
        this.editPointsRuleForm.ratio =
          this.currentPointsRule.baseRatio.split(":")[0] +
          " : " +
          parseInt(this.currentPointsRule.baseRatio.split(":")[1]) * this.editPointsRuleForm.activityMultiple;
      },
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/mixin.scss";
@import "~@/styles/variables.scss";

.pointsRules-container {
  min-width: 920px;
}

.pointList-subTitleBar-shopInfo {
  position: absolute;
  right: 20px;
  top: 0;
  // height: 100%;
  font-size: 18px;
  // font-weight: bold;
  color: #333;
  line-height: 40px;
}

// .pointList-subTitleBar-shopInfo span {
//   margin-right: 20px;
// }

// .pointsRuleDetail-container {
//   line-height: 45px;
// }

// .pointsRuleDetail-label {
//   font-weight: bold;
//   font-size: 16px;
// }
.dataTable .dataItem .name {
  min-width: 240px !important;
}
.pointsRule-tips {
  color: #ff3f4d;
  text-align: left;
  font-size: 14px;
  margin-bottom: 30px;
}

// .pointList-content {
//   text-overflow: ellipsis;
//   overflow: hidden;
//   white-space: nowrap;
//   display: block;
//   width: 100%;
// }

// .pointsRule-checkbox {
//   width: 140px;
//   height: 45px;
//   margin: 0 auto 3px;
//   max-width: 100%;
//   .el-checkbox-button__inner {
//     border-left: 1px solid #dcdfe6;
//     border-radius: 6px !important;
//   }
// }

// .pointsRule-textarea {
//   textarea {
//     font-family: inherit;
//     font-size: 16px !important;
//     padding-right: 60px !important;
//   }
// }

// .pointsRule-input {
//   input {
//     padding-right: 50px;
//   }
// }
</style>
