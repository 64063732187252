import http from "../http";

// 查询子企业店铺
export const slaveenterprisestorelist = params => {
  return http({
    method: "get",
    url: `/api/shop/slaveenterprisestorelist`,
    params
  });
};

export const storeList = params => {
  return http({
    method: "get",
    url: `/api/shop/storeList`,
    params
  });
};

export const shopList = () => {
  return http({
    method: "get",
    url: `/api/shop/list`
  });
};

export const offlineShops = params => {
  return http({
    method: "get",
    url: `/api/shop/offlineshops`,
    params
  });
};

export const shopDevices = params => {
  return http({
    method: "get",
    url: `/api/shop/device`,
    params
  });
};

export const onlineShops = params => {
  return http({
    method: "get",
    url: `/api/shop/onlineshops`,
    params
  });
};

export const shopInfo = params => {
  return http({
    method: "get",
    url: `/api/shop/info`,
    params
  });
};

export const downloadCPMShop = params => {
  return http({
    method: "get",
    url: `/api/shop/offlineshopscsv`,
    params,
    responseType: "blob"
  });
};
export const downloadMPMShop = params => {
  return http({
    method: "get",
    url: `/api/shop/onlineshopscsv`,
    params,
    responseType: "blob"
  });
};
export const downloadShopDeviceFile = params => {
  return http({
    method: "get",
    url: `/api/shop/devicescsv`,
    responseType: "blob",
    params
  });
};
export const downloadAllDeviceFile = params => {
  return http({
    method: "get",
    url: `/api/shop/alldevicescsv`,
    responseType: "blob",
    params
  });
};
export const downloadStoresFile = params => {
  return http({
    method: "get",
    url: `/api/shop/storesfile`,
    responseType: "blob",
    params
  });
};
export const getEffectivePointList = params => {
  return http({
    method: "get",
    url: `/api/point/validpointsrules`,
    params
  });
};
export const getHistoricalPointList = params => {
  return http({
    method: "get",
    url: `/api/point/historypointsrules`,
    params
  });
};
export const getPointTypeOptions = params => {
  return http({
    method: "get",
    url: `/api/point/pointtypes`,
    params
  });
};
export const getPointsRuleDetail = params => {
  return http({
    method: "get",
    url: `/api/point/ruledetail`,
    params
  });
};
export const submitCreatePointsRule = data => {
  return http({
    method: "post",
    url: `/api/point/create`,
    data: data
  });
};
export const submitEditPointsRule = data => {
  return http({
    method: "put",
    url: `/api/point/edit`,
    data: data
  });
};
